import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reviewCount: 0,
  ratingCount: 0,
};

const reviewCountSlice = createSlice({
  name: "reviewCount",
  initialState,
  reducers: {
    setReviewCount: (state, action) => {
      state.reviewCount = action.payload;
    },
    setRatingCount: (state, action) => {
      state.ratingCount = action.payload;
    },
  },
});

export const { setReviewCount, setRatingCount } = reviewCountSlice.actions;
export default reviewCountSlice.reducer;
