import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  firstName: "",
  lastName: "",
  dateOfBirth: moment().valueOf(),
  phoneNumber: "",
  address: "",
  is_host: "",
  host_id: "",
  is_user_verified: "",
  profile_picture: "",
  all_data: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setDateOfBirth: (state, action) => {
      state.dateOfBirth = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setIsHost: (state, action) => {
      state.is_host = action.payload;
    },
    setHostId: (state, action) => {
      state.host_id = action.payload;
    },
    setIsUserVerified: (state, action) => {
      state.is_user_verified = action.payload;
    },
    setProfilePicture: (state, action) => {
      state.profile_picture = action.payload;
    },
    setAllData: (state, action) => {
      state.all_data = action.payload;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setDateOfBirth,
  setPhoneNumber,
  setAddress,
  setIsHost,
  setHostId,
  setIsUserVerified,
  setProfilePicture,
  setAllData,
} = userSlice.actions;
export default userSlice.reducer;
