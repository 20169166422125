import { createSlice } from "@reduxjs/toolkit";
const initialState = null;

const reservationConfirmedSlice = createSlice({
  name: "reservationConfirmed",
  initialState,
  reducers: {
    setReservationConfirmed: (state, action) => {
      return action.payload;
    },
  },
});

export const { setReservationConfirmed } = reservationConfirmedSlice.actions;
export default reservationConfirmedSlice.reducer;
