// src/redux/reservationDataSlice.js

import { createSlice } from "@reduxjs/toolkit";

const reservationChangeDataSlice = createSlice({
  name: "reservationChangeData",
  initialState: {
    reservationChangeData: [],
  },
  reducers: {
    setReservationChangeData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setReservationChangeData } = reservationChangeDataSlice.actions;

export default reservationChangeDataSlice.reducer;
