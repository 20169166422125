// reviewReducer.js (Example reviewReducer)

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reviews: [],
};

const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setReviews: (state, action) => {
      state.reviews = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setReviews } = reviewSlice.actions;

export default reviewSlice.reducer;
