import { createSlice } from "@reduxjs/toolkit";

const redeemCoinAmountSlice = createSlice({
  name: "redeemCoinAmount",
  initialState: 0,
  reducers: {
    setRedeemCoinAmount: (state, action) => {
      return action.payload;
    },
  },
});

export const { setRedeemCoinAmount } = redeemCoinAmountSlice.actions;
export default redeemCoinAmountSlice.reducer;
