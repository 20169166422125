// src/redux/tokenSlice.js

import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "token",
  initialState: {
    accessToken: null,
    isAuthenticated: false,
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAccessToken, setAuthenticated } = tokenSlice.actions;

export default tokenSlice.reducer;
