import axios from "axios";
import moment from "moment";

// .........................................................................................................................................................
//Keys

export const BASE_URL = process.env.REACT_APP_LIVE_API_BASE_URL;
// export const BASE_URL = process.env.REACT_APP_LOCAL_API_BASE_URL;

// ..........................................................................................................................................................

export const getAllHostawayReviews = async (authToken, listingId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-all-hostaway-reviews?property_id=${listingId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const makePartnerXReservation = async (
  authToken,
  listingId,
  startDate,
  endDate,
  email,
  guestData,
  firstName,
  lastName,
  totalPrice,
  cardNumber,
  cardHolderName,
  expirationYear,
  expirationMonth,
  cvv,
  address,
  phoneNumber
) => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const payload = {
    listingId: listingId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    guestData: guestData,
    startDate: startDate,
    endDate: endDate,
    phoneNumber: phoneNumber,
    totalPrice: totalPrice,
    cardNumber: cardNumber,
    cardHolderName: cardHolderName,
    expirationYear: expirationYear,
    expirationMonth: expirationMonth,
    cvv: cvv,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/create-partnerX-reservation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const makeSafelyReservation = async (
  authToken,
  reservationId,
  startDate,
  endDate,
  guestData,
  firstName,
  lastName,
  phoneNumber,
  dateOfBirth,
  email,
  address,
  listingId,
  selectedItem,
  totalPrice
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const payload = {
      reservationId: reservationId,
      startDate: startDate,
      endDate: endDate,
      guestData: guestData,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      dateOfBirth: dateOfBirth,
      email: email,
      address: address,
      listingId: listingId,
      selectedItem: selectedItem,
      totalPrice: totalPrice,
    };

    const response = await axios.post(
      `${BASE_URL}/make-safely-reservation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getPartnerXReview = async (authToken, listingId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-partnerX-reviews?property_id=${listingId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const GetHostwayAvailableDates = async (authToken, listingId) => {
  try {
    const payload = {
      listingId: listingId,
    };

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/get-hostway-available-dates`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetVacanzaAvailableDates = async (authToken, listingId) => {
  try {
    const payload = {
      listingId: listingId,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(
      `${BASE_URL}/get-vacanza-available-dates`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const HostwayPriceCalculation = async (
  authToken,
  listingId,
  startDate,
  endDate,
  numberOfGuests
) => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const payload = {
    listingId: listingId,
    startDate: startDate,
    endDate: endDate,
    numberOfGuests: numberOfGuests,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/hostway-price-calculation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateHostwayReservation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-hostway-reservation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getAllPropertyData = async (authToken, owner, property_id) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const payload = {
      owner: owner,
      property_id: property_id,
    };

    const response = await axios.post(
      `${BASE_URL}/get-single-property-data`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getSinglePropertyData = async (authToken, unit_id) => {
  try {
    const payload = {
      unit_id: unit_id,
    };

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/get-parnterx-single-property`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getVacanzaSingleProperty = async (authToken, unit_id) => {
  try {
    const payload = {
      unit_id: parseInt(unit_id),
    };

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/get-vacanzastays-single-property`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const PartnerXPriceCalculation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/partnerX-price-calculation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
// Wishlist

export const getWishLists = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-wishlist?email=${email}`,
      { headers }
    );
    return response.data.wishlist;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const AddWishLists = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      `${BASE_URL}/wishlist`,
      JSON.stringify(payload),
      { headers }
    );
    return response.data.wishlist;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const RemoveWishLists = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      `${BASE_URL}/remove-wishlist`,
      JSON.stringify(payload),
      { headers }
    );
    return response.data.wishlist;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getWishListsProperty = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-wishlist-properties?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// new wishlist group apis

export const checkItemInWishlist = async (authToken) => {
  try {
    const response = await axios.get(`${BASE_URL}/wishlist`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching wishlist data:", error);
    throw error;
  }
};

export const fetchWishlistGroups = async (authToken) => {
  try {
    const response = await axios.get(`${BASE_URL}/wishlist/groups`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Error fetching wishlist categories:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching wishlist categories:", error);
    throw error;
  }
};

// .........................................................................................................................................................

// Add a product to old wishlist
export const addRemoveItemToWishlist = async (authToken, productId, productName, wishlistId, action) => {
  try {
    await axios.post(`${BASE_URL}/wishlist`,
      {
        wishlist: {
          id: productId, // This should be a number or string
          name: productName, // Ensure this is a string
          action: action,  // Should be a string ("add" or "remove")
        },
        group_id: wishlistId, // Ensure group_id is a number or null
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Ensure authToken is a string
        },
      }
    );
  } catch (error) {
    if (error.response) {
      console.error("Error adding to wishlist:", error.response.data);
    } else {
      console.error("Error adding to wishlist:", error.message);
    }
    throw error;
  }
};

// Create a new wishlist Group
export const createWishlistGroup = async (authToken, newWishlistName) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/wishlist/group`,
      {
        group_name: newWishlistName,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    if (response.status === 201) {
      return response.data; // Return the created wishlist group data
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error("Wishlist with this name already exists.");
    } else {
      throw new Error("Error creating wishlist.");
    }
  }
};

// Delete a wishlist group
export const deleteWishlistGroup = async (authToken, groupId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/wishlist/group`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        group_id: groupId,
      },
    });

    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error("Group cannot be deleted, it might have items.");
    } else {
      throw new Error("Error deleting group.");
    }
  }
};

// Fetch property group ID
export const fetchPropertyById = async (authToken, groupId) => {
  try {
    const response = await axios.get(`${BASE_URL}/wishlist/groups/${groupId}/items`, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Include the token
      },
    });

    return response.data; // Assuming the response is an array of products
  } catch (error) {
    console.error("Error fetching product details:", error);
    throw error;
  }
};

// Wishlist end
// .........................................................................................................................................................

export const GetPartnerXAvailableDates = async (authToken, listingId) => {
  const endDate = moment().add(6, "months").format("YYYY-MM-DD");
  try {
    const payload = {
      unit_id: listingId,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: endDate,
    };

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/get-partnerx-available-dates`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateReservation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/update-reservation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const UpdateReservationStatus = async (authToken, ReservationId) => {
  const payload = {
    ReservationId: ReservationId,
  };

  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/update-reservation-status`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const IsUserExist = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/isUserExist`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const IsUserExistPhone = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/isUserExistPhone`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const Login = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const GetReservationInfo = async (authToken, email) => {
  const payload = {
    email: email,
  };

  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(
      `${BASE_URL}/get-reservation-info`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveReservationSafelyIds = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(
      `${BASE_URL}/save-reservation-safelyIds`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CancelReservation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(
      `${BASE_URL}/traveler/cancel-reservation`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SendEmailToHosForCancelOrUpdateRequest = async (
  authToken,
  payload
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(
      `${BASE_URL}/cancel-update-send-email`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SendOtpEmail = async (email) => {
  const payload = {
    email: email,
  };

  try {
    const response = await axios.post(`${BASE_URL}/send-otp-email`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SendOtpPhone = async (phone_number) => {
  const payload = {
    toNumber: phone_number,
  };
  try {
    const response = await axios.post(`${BASE_URL}/send-otp-twilio`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const VerifyOtp = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/verify-otp`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const ForgotPassword = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/forget-password`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const AptxPayment = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/aptx-payment`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const VerifyPhoneOtp = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/send-otp-twilio`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const sendReservationDetailsToFriendEmail = async (
  authToken,
  payload
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/send-reservation-email-message-to-friend`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const sendReservationDetailsToGuestAndHost = async (
  authToken,
  payload
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/send-reservation-email-to-host-and-guest`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const BecomeHost = async (authToken, email) => {
  const payload = {
    email: email,
  };

  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  try {
    const response = await axios.post(`${BASE_URL}/become-a-host`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateRoomId = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/create-room-id`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetHostRooms = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-host-rooms?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getUserRooms = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-user-rooms?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateNewProperty = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-new-vacanza-property`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetHostProperties = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/get-vacanzastays-host-property`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateSeasonGroup = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/add-new-season-group`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateSeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/add-new-season`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetSeasonGroup = async (authToken, host_Email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-seasons-group?host_Email=${host_Email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetSeason = async (authToken, host_Email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-seasons?host_Email=${host_Email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateSeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/update-season`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const AddNewRangeToSeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/add-new-season-with-same-id`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateSeasonName = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/update-season-group-name`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................


// .........................................................................................................................................................

export const AssignPropertyGroup = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/assign-property-to-season`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const AssignPropertyGroupUpdate = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.patch(
      `${BASE_URL}/update-property-seasons-by-id`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const AssignPropertyGroupUpdateBulk = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.patch(
      `${BASE_URL}/update-property-seasons-by-property-id`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const CopySeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/clone-season`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................


// .........................................................................................................................................................

export const handleDragAndDrop = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.put(
      `${BASE_URL}/update-season-order`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................



// .........................................................................................................................................................

export const GetAssignPropertyGroup = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-assign-property-to-season-all?season_group_id=${payload}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................


// .........................................................................................................................................................

export const GetCalendarBlockDates = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-all-block-property_date`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const GetCalendarRulesMulti = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-calendar-rules`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const GetCalendarBlockDatesSingle = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-block-data-property-id?property_id=${payload}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................


// .........................................................................................................................................................

export const GetCalendarSingle = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-single-calendar-events?property_id=${payload}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................


// .........................................................................................................................................................

export const UpdateSlotBookingMultiCalendar = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/block-property_date`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const UpdateSlotBookingMultiCalendarUpdate = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.patch(
      `${BASE_URL}/patch-block-property_date`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................


// .........................................................................................................................................................

export const addMultiCalendarNewRules = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-calendar-rules`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const updateMultiCalendarNewRules = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.patch(
      `${BASE_URL}/update-calendar-rules`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const DeleteBlockDates = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.delete(
      `${BASE_URL}/delete-block-property_date?id=${payload}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

// .........................................................................................................................................................

export const DeleteRulesForMultiCalendar = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.delete(
      `${BASE_URL}/delete-calendar-rules`, payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................




export const UpdateRangeToSeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/update-new-season-with-same-id`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};


// .........................................................................................................................................................

export const UpdateSeasonOrder = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.put(
      `${BASE_URL}/update-season-order`,
      { order: payload },
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating season order:', error);
    throw error;
  }
};


// .........................................................................................................................................................

export const DeleteSingleSeasonRange = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/delete-single-season`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const DeleteSeasonGroup = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/delete-season-group`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const DeleteSeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/delete-season`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetAllSeasons = async (authToken, host_Email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-all-seasons?host_Email=${host_Email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetAllSeasonsThroughGroup = async (
  authToken,
  host_Email,
  season_group_id
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-seasons-through-group?host_Email=${host_Email}&season_group_id=${season_group_id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const AddSeasonToProperty = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/add-season-to-property`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetPropertySeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/get-season-added-to-property`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const createVacanzaReservation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-vacanza-reservation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const SaveCalendarEvents = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-calendar-events`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetCalendarEvents = async (authToken, PropertyId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-calendar-events?property_id=${PropertyId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const ImportICS = async (authToken, url) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(`${BASE_URL}/import-ics?url=${url}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const ExportEventsUrl = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/upload-ics-to-s3`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const SaveImportUrl = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/save-import-url`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetImportUrl = async (authToken, PropertyId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-import-url?property_id=${PropertyId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetPropertyWithId = async (authToken, PropertyId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-property-with-id?property_id=${PropertyId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateProperty = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/update-vacanza-property`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const SinglePriceData = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/single-price-management`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const GetSinglePriceEvents = async (authToken, PropertyId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-single-price-events?property_id=${PropertyId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const GetAdditionalCharges = async (authToken, PropertyId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-additional-prices?property_id=${PropertyId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateAdditionalCharges = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/update-additional-charges`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const HostwayAffiliate = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/affiliate-hostway`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UserSignUp = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/signup`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//.........................................................................................................................................................
export const sendEmailSubHostJoin = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/send-email-subhost`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//.........................................................................................................................................................
export const BecomeSubHost = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/become-a-subhost`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveInquiryPropertyDataDB = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-inquiry-property-data`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetInquiryPropertyDataByRoomId = async (authToken, room_id) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-inquiry-property-data-by-room_id?room_id=${room_id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const ApproveBooking = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/approve-booking`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveOfferDataDB = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/special-offer-data`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const VacanzaPriceCalculation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/vacanza-price-calculation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetOfferDataByRoomId = async (authToken, room_id) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-offer-property-data-by-room_id?room_id=${room_id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const PropertyViewCount = async (property_id) => {
  const payload = {
    property_id: property_id,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/increase-property-view-count`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const PropertyFavoriteCount = async (authToken, property_id) => {
  const payload = {
    property_id: property_id,
  };

  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/increase-property-favorite-count`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const PropertyConversionCount = async (authToken, property_id) => {
  const payload = {
    property_id: property_id,
  };

  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/increase-property-conversion-count`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetAllPropertiesFromDB = async (ids, page) => {
  const payload = {
    propertyTypeIds: ids,
    page: page,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/get-all-properties-from-db`,
      payload
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UploadPropertyImagesToS3 = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/upload-property-images-to-s3`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveTaxInformationForHost = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-tax-information-for-host`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const CreateReviewOnProperty = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-new-review`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetReviewOnProperty = async (authToken, property_id) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-reviews-for-property?property_id=${property_id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetNearestProperties = async (property_id, owner) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/get-nearest-properties?property_id=${property_id}&owner=${owner}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const CreateReviewForUser = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-review-for-user`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................
export const SaveNewPricesToSeason = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-new-price-to-season`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const GetNewAllSeasonDataByPropertyId = async (
  authToken,
  PropertyId
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-new-price-all-seasons?property_id=${PropertyId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetReservationInfoById = async (authToken, email) => {
  const payload = {
    email: email,
  };

  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/get-reservation-info-by-id`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveAskForMoneyData = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(
      `${BASE_URL}/save-ask-for-money-data`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};


// .........................................................................................................................................................

export const SendMoneyData = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(
      `${BASE_URL}/send-money-data`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error;
  }
};


// .........................................................................................................................................................

export const GetAskForMoneyHostData = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-ask-for-money-host-data?email=${email}`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetAskForMoneyUserData = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-ask-for-money-user-data?email=${email}`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateAskForMoneyStatus = async (authToken, Id, Status) => {
  const payload = {
    Id: Id,
    Status: Status,
  };
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/update-ask-for-money-status`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetPayoutDetailsForHost = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-payouts-for-host?email=${email}`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetReviewOnEmail = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-review-for-user?email=${email}`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const AddReviewReply = async (authToken, { reviewId, hostEmail, replyMessage }) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(
      `${BASE_URL}/replyReview`,
      {
        reviewId: reviewId,
        hostEmail: hostEmail,
        replyMessage: replyMessage,
      },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    return { error: error.message || "An error occurred while adding the reply." };
  }
};



// .........................................................................................................................................................

export const GetSubHostCreatedByHost = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-sub-host-created-by-host?email=${email}`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SwitchUserHost = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(`${BASE_URL}/switch-user`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
// .........................................................................................................................................................

export const GetApprovedBooking = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(
      `${BASE_URL}/get-approved-booking`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateInquiryApproveStatus = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(
      `${BASE_URL}/update-inquiry-decline-status`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateProfileData = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.patch(
      `${BASE_URL}/update-profile-info`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const ResolutionReportToAdmin = async (authToken, Id, email) => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const payload = {
    Id: Id,
    email: email,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/report-resolution-to-admin`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetReservationInfoHostAPI = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/get-reservation-info-for-host?email=${email}`,

      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const RefundPayment = async (authToken, paymentId, reason, amount) => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const payload = {
    paymentId: paymentId,
    reason: reason,
    amount: amount,
  };
  try {
    const response = await axios.post(`${BASE_URL}/refund-payment`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetPaymentId = async (authToken, email, time) => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const payload = {
    email: email,
    time: time,
  };
  try {
    const response = await axios.post(`${BASE_URL}/get-payment-id`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateExistingReservation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/update-reservations`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateFirstTimePropertyStatus = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const payload = {
      email: email,
    };
    const response = await axios.post(
      `${BASE_URL}/update-firstTime-property-status`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const AddHostBankAccount = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/add-bank-account`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetHostBankAccount = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-host-bank-details?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateSpecialOfferStatus = async (
  authToken,
  room_id,
  property_id
) => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const payload = {
    room_id: room_id,
    property_id: property_id,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/update-special-offer-expire-status`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateInquirySpecialOfferStatus = async (
  authToken,
  room_id,
  property_id
) => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const payload = {
    room_id: room_id,
    property_id: property_id,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/update-special-offer-status`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getHelpCenterData = async (id) => {
  try {
    const res = await axios.get(
      `https://public-api.wordpress.com/rest/v1.1/sites/vacanzastays.wordpress.com/posts/slug:${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getHostBlogsData = async (id) => {
  try {
    const res = await axios.get(
      `https://public-api.wordpress.com/rest/v1.1/sites/vacanzastays.wordpress.com/posts/?category=${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const RemoveImportUrl = async (authToken, id) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.delete(
      `${BASE_URL}/delete-a-import-url?id=${id}`,
      { headers }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SearchLatLngGetLocations = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/search-lat-lng-get-location`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getCouponList = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-coupons-list?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateNewCoupon = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-new-coupon`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const deleteCoupon = async (authToken, id) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.delete(
      `${BASE_URL}/delete-a-coupon?id=${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateCoupon = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.patch(
      `${BASE_URL}/update-coupon-by-id`,
      payload,
      { headers }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetCouponsListByPropertyId = async (
  authToken,
  property_id,
  email
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-coupons-list-by-property_id?property_id=${property_id}&email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveUsedCoupon = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/save-used-coupon`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const DeleteAVacanzaProperty = async (authToken, PropertyId, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.delete(
      `${BASE_URL}/delete-a-vacanza-property?property_id=${PropertyId}&email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateNewVacanzaPropertyDraft = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-new-vacanza-property-draft`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const retrieveListingFromSearch = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/property-map-search`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetTaxInformationForHost = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-tax-information-for-host?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const DeleteTaxInformation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.delete(
      `${BASE_URL}/delete-tax-information-for-host?email=${payload?.email}&id=${payload?.id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UpdateTaxInformationForHost = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.patch(
      `${BASE_URL}/update-tax-information-for-host`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const MakePropertyArchiveOrUnArchive = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/make-property-archive-or-unarchive`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const UploadResolutionImagesToS3 = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/upload-resolution-images-to-s3`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveViewPropertyLog = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-view-property-log`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetViewPropertyLog = async (authToken, propertyId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-view-property-log?propertyId=${propertyId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const PropertyTransferRequestForm = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/property-transfer-request-form`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetTransferRequestForm = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-transfer-request-form?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CancelOrConfirmTransferRequest = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.patch(
      `${BASE_URL}/cancel-or-confirm-transfer-property-request`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const escalateRequestToAdmin = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/escalate-request-to-admin`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const hostChangeExistingReservation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/host-change-existing-reservation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getUserExistingReservations = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-host-change-existing-reservation?guestEmail=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const cancelOrConfirmHostReservation = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/cancel-or-confirm-host-change-existing-reservation`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getVideoUrl = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/get-video_url`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const saveGlobalPropertyOffers = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-global-property-offers`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getGlobalPropertyOffers = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-global-property-offers?host_email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getAllUnreadCountForHostMessages = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-all-unread-messages-count-for-host?host_email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const getAllUnreadCountForUserMessages = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-all-unread-messages-count-for-user?user_email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const VerifyAccess = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(
      `${BASE_URL}/verify-access?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const sendSplitPaymentEmail = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/send-split-payment-email`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveMakePropertyArchiveOrUnArchiveData = async (
  authToken,
  payload
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-archive-property-data`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const RemoveMakePropertyArchiveOrUnArchiveData = async (
  authToken,
  property_id
) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.delete(
      `${BASE_URL}/remove-property-archive-data?property_id=${property_id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SendDataToZapier = async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/zapier/send-data-to-zapier`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const DistributeSuperCoins = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/distribute-jurnie-supercoins`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetSuperCoinsForEmail = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-jurnie-supercoins?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const ReduceSuperCoins = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/reduce-jurnie-supercoins`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const SaveRefundSuperCoins = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/save-refund-jurnie-supercoins`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const HostwayExtraCharge = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/hostway-extra-offline-charge`,
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetPaymentBreakdown = async (authToken, email, reservationId) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-payment-breakdown?email=${email}&reservationId=${reservationId}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const DefaultSelfCheckIn = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(`${BASE_URL}/default-self-in`, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetDefaultSelfCheckIn = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-default-self-check-in?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GlobalCheckInOutTime = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/global-check-in-out-time`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetGlobalCheckInOutTime = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-global-check-in-out-time?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const CreateSuperCoinsTransection = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/create-supercoins-transection`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetSuperCoinsTransection = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-super-coins-transection?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetReviewOnPropertyByUser = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-reviews-for-property-by-user?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const AddNewPropertyPriceAndMinNights = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.post(
      `${BASE_URL}/add-new-property-price-and-min-nights`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// .........................................................................................................................................................

export const GetNewTapeChartPropertyPrice = async (authToken, email) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const response = await axios.get(
      `${BASE_URL}/get-new-property-price-and-min-nights?email=${email}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};


// .....................................................

export const ReferToHost = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.post(`${BASE_URL}/refer-host`, payload, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
}



// .....................................................

export const getCalendarHostData = async (authToken, hostEmail, selectedIndex, limit) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await axios.get(`${BASE_URL}/get-calender-data?host_email=${hostEmail}&page=${selectedIndex}&limit=${limit}`, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
}


// .....................................................


export const createPaymentIntent = async (authToken, payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${BASE_URL}/create-payment-intent`, payload, { headers });
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error;
  }
};

