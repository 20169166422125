import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    steps: 0,
    emailStepper: "",
    firstName: "",
    lastName: "",
    legalOrganizationStepper: false,
    legalOrganizationLogoStepper: null,
    legalOrganizationNameStepper: "",
    dobStepper: "",
    phoneStepper: "",
    passwordStepper: "",
    emailVerifiedStepper: false,
    phoneVerifiedStepper: false,
    govtVerifyStepper: "",
    cameraImageStepper: null,
    profileImageStepper: "",
};

const signupStepperSlice = createSlice({
    name: "signupStepper",
    initialState,
    reducers: {
        setSteps: (state, action) => {
            state.steps = action.payload;
        },
        setEmailStepper: (state, action) => {
            state.emailStepper = action.payload;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
        },
        setLegalOrganizationStepper: (state, action) => {
            state.legalOrganizationStepper = action.payload;
        },
        setLegalOrganizationLogoStepper: (state, action) => {
            state.legalOrganizationLogoStepper = action.payload;
        },
        setLegalOrganizationNameStepper: (state, action) => {
            state.legalOrganizationNameStepper = action.payload;
        },
        setDobStepper: (state, action) => {
            state.dobStepper = action.payload;
        },
        setPhoneStepper: (state, action) => {
            state.phoneStepper = action.payload;
        },
        setPasswordStepper: (state, action) => {
            state.passwordStepper = action.payload;
        },
        setEmailVerifiedStepper: (state, action) => {
            state.emailVerifiedStepper = action.payload;
        },
        setPhoneVerifiedStepper: (state, action) => {
            state.phoneVerifiedStepper = action.payload;
        },
        setGovtVerifyStepper: (state, action) => {
            state.govtVerifyStepper = action.payload;
        },
        setCameraImageStepper: (state, action) => {
            state.cameraImageStepper = action.payload;
        },
        setProfileImageStepper: (state, action) => {
            state.profileImageStepper = action.payload;
        },
        resetStepper: () => initialState,
    },
});

export const {
    setSteps,
    setEmailStepper,
    setFirstName,
    setLastName,
    setLegalOrganizationStepper,
    setLegalOrganizationLogoStepper,
    setLegalOrganizationNameStepper,
    setDobStepper,
    setPhoneStepper,
    setPasswordStepper,
    setEmailVerifiedStepper,
    setPhoneVerifiedStepper,
    setGovtVerifyStepper,
    setCameraImageStepper,
    setProfileImageStepper,
    resetStepper,
} = signupStepperSlice.actions;
export default signupStepperSlice.reducer;
