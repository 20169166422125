import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import propertyReducer from "../reducer/propertyReducer";
import selectedItemReducer from "../reducer/selectedItemReducer";
import startEndDateReducer from "../reducer/startEndDateReducer";
import reviewReducer from "../reducer/reviewReducer";
import bookNowDateReducer from "../reducer/bookNowDateReducer";
import emailReducer from "../reducer/emailReducer";
import userDetailsReducer from "../reducer/userDetailsReducer";
import reservationConfirmedCardReducer from "../reducer/reservationConfirmedCardReducer";
import reservationPriceCalculationReducer from "../reducer/reservationPriceCalculationReducer";
import listingIdReducer from "../reducer/listingIDReducer";
import searchHotelReducer from "../reducer/searchHotelReducer";
import dynamicRouteFormattedNameReducer from "../reducer/dynamicRouteFormattedNameReducer";
import cancellationPolicyReducer from "../reducer/cancellationPolicyReducer";
import tokenReducer from "../reducer/tokenReducer";
import socialLoginDataReducer from "../reducer/socialLoginDataReducer";
import reservationChangeReducer from "../reducer/reservationChangeReducer";
import loadingReducer from "../reducer/loadingReducer";
import reviewCountReducer from "../reducer/reviewCountReducer";
import searchHeaderDataReducer from "../reducer/searchMapHeaderDataReducer";
import selectedCouponReducer from "../reducer/selectedCouponReducer";
import draftVacnzaNewProperty from "../reducer/draftVacanzaNewProperty";
import responsiveReducer from "../reducer/responsiveReducer";
import superCoinsReducer from "../reducer/superCoinsReducer";
import redeemCoinAmountReducer from "../reducer/redeemCoinAmountReducer";
import signupStepperReducer from "../reducer/signupStepperReducer";
import tapeChartReducer from "../reducer/tapeChartReducer"

const rootReducer = combineReducers({
  property: propertyReducer,
  selectedItem: selectedItemReducer,
  startEndDate: startEndDateReducer,
  reviews: reviewReducer,
  bookNowDates: bookNowDateReducer,
  email: emailReducer,
  userData: userDetailsReducer,
  reservationPriceCalculation: reservationPriceCalculationReducer,
  reservationConfirmedData: reservationConfirmedCardReducer,
  listingId: listingIdReducer,
  searchHotel: searchHotelReducer,
  formattedName: dynamicRouteFormattedNameReducer,
  cancellationPolicy: cancellationPolicyReducer,
  token: tokenReducer,
  socialLogin: socialLoginDataReducer,
  reservationChangeData: reservationChangeReducer,
  loading: loadingReducer,
  reviewCount: reviewCountReducer,
  mapSearch: searchHeaderDataReducer,
  selectedCoupon: selectedCouponReducer,
  draftVacnzaNewPropertyData: draftVacnzaNewProperty,
  isMobile: responsiveReducer,
  superCoins: superCoinsReducer,
  redeemCoinAmount: redeemCoinAmountReducer,
  signupStepper: signupStepperReducer,
  tapeChartData: tapeChartReducer
});

const persistConfig = {
  key: "root", // Key under which your persisted data will be stored. You can change this to anything you like.
  storage, // The storage mechanism you imported earlier (local storage in this example).
  whitelist: [
    "selectedItem",
    "cancellationPolicy",
    "email",
    "userData",
    "reservationPriceCalculation",
    "reservationConfirmedData",
    "token",
    "socialLogin",
    "draftVacnzaNewPropertyData",
    "isMobile",
    "superCoins",
    "signupStepper",
  ], // An array of reducer keys to persist. You can choose which reducers to persist, leave empty to persist all.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disables the serializable check middleware
    }),
});

const persistor = persistStore(store);

export { store, persistor };
