import React, { Suspense, useState, lazy, useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import Loader from "./Layout/Loader/index";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536 //
    }
  }
});
const root = createRoot(document.getElementById("root"));

root.render(

  <GoogleOAuthProvider clientId="667426911882-57u1n2sd7vnd3907g0uf5j5umv2eehbl.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </Suspense>
    </ThemeProvider>
    <ToastContainer
    // position="top-center" 
    // autoClose={5000} 
    // hideProgressBar={false}
    // newestOnTop={false}
    // closeOnClick
    // rtl={false}
    // pauseOnFocusLoss
    // draggable
    // pauseOnHover
    />
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);
