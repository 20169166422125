import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllPropertiesFromDB } from "../api/api";

const initialState = {
  data: [],
  isLoading: true,
  error: null,
  page: 1,
  lastData: false,
};

export const fetchPropertyData = createAsyncThunk(
  "property/fetchPropertyData",
  async ({ selectedIds, pageIndex }, { rejectWithValue }) => {
    try {
      const PropertyData = await GetAllPropertiesFromDB(selectedIds, pageIndex);
      return PropertyData;
    } catch (error) {
      return rejectWithValue("Failed to fetch property data.");
    }
  }
);

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPropertyData.fulfilled, (state, action) => {
        state.isLoading = false;

        const newData = action.payload.data;

        // Check if data is iterable (array)
        if (Array.isArray(newData)) {
          if (newData.length > 0 && action.payload.page > 1) {
            state.data = [...state.data, ...newData];
          } else {
            state.data = newData;
          }
        }
        state.isLoading = false;
        state.lastData = action.payload.lastData;
        state.page = action.payload.page + 1;
      })
      .addCase(fetchPropertyData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default propertySlice.reducer;
