import { createSlice } from "@reduxjs/toolkit";
const initialState = null;

const socialLoginDataSlice = createSlice({
  name: "socialLoginData",
  initialState,
  reducers: {
    setSocialLoginData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSocialLoginData } = socialLoginDataSlice.actions;
export default socialLoginDataSlice.reducer;
