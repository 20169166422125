import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCouponData: "",
};

const selectedCouponData = createSlice({
  name: "selectedCouponData",
  initialState,
  reducers: {
    setSelectedCouponData: (state, action) => {
      state.selectedCouponData = action.payload;
    },
  },
});

export const { setSelectedCouponData } = selectedCouponData.actions;

export default selectedCouponData.reducer;
