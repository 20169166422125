import { createSlice } from "@reduxjs/toolkit";

const formattedNameSlice = createSlice({
  name: "formattedName",
  initialState: null,
  reducers: {
    setFormattedName: (state, action) => {
      return action.payload;
    },
  },
});

export const { setFormattedName } = formattedNameSlice.actions;
export default formattedNameSlice.reducer;
