import { createSlice } from "@reduxjs/toolkit";

const superCoinSlice = createSlice({
  name: "coins",
  initialState: 0,
  reducers: {
    setCoins: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCoins } = superCoinSlice.actions;
export default superCoinSlice.reducer;
