import { createSlice } from "@reduxjs/toolkit";

const startEndDateSlice = createSlice({
  name: "startEndDate",
  initialState: {
    startDate: null,
    endDate: null,
    dateChanged: false,
  },
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setDateChanged: (state, action) => {
      state.dateChanged = action.payload;
    },
  },
});

export const { setStartDate, setEndDate, setDateChanged } =
  startEndDateSlice.actions;
export default startEndDateSlice.reducer;
