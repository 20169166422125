import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  region: "",
  country: "",
  monthStart: "",
  monthEnd: "",
  flexibleDays: "",
  adultCount: "",
  childrenCount: "",
  petCount: "",
  infantCount: "",
  mapCoordinates: {},
  lat: "",
  lng: "",
  searchAddress: "",
  selectedIds: [],
  minPrice: 100,
  maxPrice: 4000,
  bedrooms: "",
  bathrooms: "",
  beds: "",
  selectedAmenities: [],
  selectedPropertyIds: [],
};

const searchMapDataSlice = createSlice({
  name: "SearchHeaderData",
  initialState,
  reducers: {
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setMonthStart: (state, action) => {
      state.monthStart = action.payload;
    },
    setMonthEnd: (state, action) => {
      state.monthEnd = action.payload;
    },
    setMapCoordinates: (state, action) => {
      state.mapCoordinates = action.payload;
    },
    setSelectedPropertyIds: (state, action) => {
      state.selectedPropertyIds = action.payload;
    },
    setFlexibleDays: (state, action) => {
      state.flexibleDays = action.payload;
    },
    setAdultCount: (state, action) => {
      state.adultCount = action.payload;
    },
    setChildrenCount: (state, action) => {
      state.childrenCount = action.payload;
    },
    setPetCount: (state, action) => {
      state.petCount = action.payload;
    },
    setInfantCount: (state, action) => {
      state.infantCount = action.payload;
    },
    setLat: (state, action) => {
      state.lat = action.payload;
    },
    setLng: (state, action) => {
      state.lng = action.payload;
    },
    setSearchAddress: (state, action) => {
      state.searchAddress = action.payload;
    },
    setSelectedIdsDispatch: (state, action) => {
      state.selectedIds = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setBedrooms: (state, action) => {
      state.bedrooms = action.payload;
    },
    setBathrooms: (state, action) => {
      state.bathrooms = action.payload;
    },
    setBeds: (state, action) => {
      state.beds = action.payload;
    },
    setAmenities: (state, action) => {
      state.selectedAmenities = action.payload;
    },
  },
});

export const {
  setRegion,
  setCountry,
  setMonthStart,
  setMonthEnd,
  setMapCoordinates,
  setFlexibleDays,
  setAdultCount,
  setChildrenCount,
  setPetCount,
  setInfantCount,
  setLat,
  setLng,
  setSearchAddress,
  setSelectedIdsDispatch,
  setMinPrice,
  setMaxPrice,
  setBedrooms,
  setBathrooms,
  setBeds,
  setAmenities,
  setSelectedPropertyIds,
} = searchMapDataSlice.actions;
export default searchMapDataSlice.reducer;
