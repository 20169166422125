import { createSlice } from "@reduxjs/toolkit";

const reservationPriceCalculationSlice = createSlice({
  name: "reservationPriceCalculation",
  initialState: null,
  reducers: {
    setReservationPriceCalculation: (state, action) => {
      return action.payload;
    },
  },
});

export const { setReservationPriceCalculation } =
  reservationPriceCalculationSlice.actions;
export default reservationPriceCalculationSlice.reducer;
