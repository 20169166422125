import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listingId: "",
};

const listingId = createSlice({
  name: "listingId",
  initialState,
  reducers: {
    setListingId: (state, action) => {
      state.listingId = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setListingId } = listingId.actions;

export default listingId.reducer;
