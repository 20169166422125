import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { retrieveListingFromSearch } from "../api/api";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  offset: 0,
  page: 1,
  pageSize: 9,
  totalPages: 0,
};

export const searchHotel = createAsyncThunk(
  "property/searchHotel",
  async (payload) => {
    try {
      const response = await retrieveListingFromSearch(payload);
      return response;
    } catch (error) {
      throw new Error("Error retrieving property listing");
    }
  }
);

const searchHotelSlice = createSlice({
  name: "searchHotel",
  initialState,
  reducers: {
    nextPage: (state) => {
      state.page += 1;
      state.offset += 9;
    },
    previousPage: (state) => {
      state.page -= 1;
      state.offset -= 9;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    resetPagination: (state, action) => {
      state.page = 1;
      state.offset = 0;
      state.pageSize = 9;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchHotel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
        state.page = action.payload?.pagination?.page;
        state.offset = action.payload?.pagination?.offset;
        state.totalPages = action.payload?.pagination?.count;
      })
      .addCase(searchHotel.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { nextPage, previousPage, setPageSize, resetPagination } =
  searchHotelSlice.actions;
export default searchHotelSlice.reducer;
