// dateSlice.js

import { createSlice } from "@reduxjs/toolkit";

const dateSlice = createSlice({
  name: "date",
  initialState: {
    minAvailableDate: null,
    maxAvailableDate: null,
    guestsData: [],
    excludedDates: [],
  },
  reducers: {
    setMinAvailableDate: (state, action) => {
      state.minAvailableDate = action.payload;
    },
    setMaxAvailableDate: (state, action) => {
      state.maxAvailableDate = action.payload;
    },
    setExcludedDates: (state, action) => {
      state.excludedDates = action.payload;
    },
    setGuestsData: (state, action) => {
      const { adultCount, childrenCount, infantCount, petCount } =
        action.payload;
      state.guestsData = { adultCount, childrenCount, infantCount, petCount };
    },
  },
});

export const {
  setMinAvailableDate,
  setMaxAvailableDate,
  setExcludedDates,
  setGuestsData,
} = dateSlice.actions;

export default dateSlice.reducer;
