import io from "socket.io-client";

export const BASE_URL = process.env.REACT_APP_LIVE_CHAT_BASE_URL;
// export const BASE_URL = process.env.REACT_APP_LOCAL_CHAT_BASE_URL;

const socket = io.connect(BASE_URL, { transports: ["polling", "websocket"] });

socket.on("connect", () => {
  console.info("Socket connected to server.");
});

socket.on("connect_error", (error) => {
  console.error("Connection Error: ", error);
});

export const connectSocket = async () => {
  socket.connect();
};

export const disconnectSocket = () => {
  socket.disconnect();
};

export const emitEvent = (event, data) => {
  socket.emit(event, data);
};

export const onEvent = (event, callback) => {
  socket.on(event, callback);
};

export const offEvent = (event, callback) => {
  socket.off(event, callback);
};

export const onAnyEvent = (callback) => {
  socket.onAny(callback);
};
