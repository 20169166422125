import { createSlice } from "@reduxjs/toolkit";

const responsiveSlice = createSlice({
  name: "isMobile",
  initialState: false,
  reducers: {
    setIsMobile: (state, action) => {
      return action.payload;
    },
  },
});

export const { setIsMobile } = responsiveSlice.actions;
export default responsiveSlice.reducer;
