// cancellationPolicySlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCancellationPolicy: null,
};

const cancellationPolicySlice = createSlice({
  name: "cancellationPolicy",
  initialState,
  reducers: {
    setSelectedCancellationPolicy: (state, action) => {
      state.selectedCancellationPolicy = action.payload;
    },
  },
});

export const { setSelectedCancellationPolicy } =
  cancellationPolicySlice.actions;
export default cancellationPolicySlice.reducer;
