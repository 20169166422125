import { createSlice } from "@reduxjs/toolkit";
const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const tapeChartPropertiesSlice = createSlice({
   name: "tapeChartProperties",
   initialState: {
      tapeChartProperties: [],
      modifiedTapChartProperties: [],
      selectedDays: daysOfWeek,
   },
   reducers: {
      setTapeChartProperties: (state, action) => ({
         ...state,
         tapeChartProperties: action.payload
      }),
      setNewsChartProperties: (state, action) => ({ ...state, modifiedTapChartProperties: action.payload }),
      setDays: (state, { payload }) => {
         const dayIndex = state.selectedDays.indexOf(payload);
         if (dayIndex === -1) {
            state.selectedDays.push(payload);
         } else {
            state.selectedDays.splice(dayIndex, 1);
         }
      }
   },
});

export const { setTapeChartProperties, setNewsChartProperties, setDays } = tapeChartPropertiesSlice.actions;
export default tapeChartPropertiesSlice.reducer;
