import { createSlice } from "@reduxjs/toolkit";

const reservationDataSlice = createSlice({
    name: "reservationData",
    initialState: {
        draftPropertyPayload: [],
        draftCurrentStep: 1,
    },
    reducers: {
        setDraftPropertyPayload: (state, action) => ({
            ...state,
            draftPropertyPayload: action.payload,
        }),
        setDraftCurrentStep: (state, action) => ({
            ...state,
            draftCurrentStep: action.payload,
        }),
    },
});

export const { setDraftPropertyPayload, setDraftCurrentStep } = reservationDataSlice.actions;

export default reservationDataSlice.reducer;
